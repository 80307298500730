import React from "react";
import { Helmet } from "react-helmet"
import StandardLayout from "../layout/StandardLayout";

const Warehousing = () => {
    return <StandardLayout>

        <Helmet>
            <title>Warehousing - Burke Shipping Services</title>
        </Helmet>

        <div className="c-hero" style={{backgroundImage: "url(/images/warehousing-hero.jpg)"}} >

        </div>

        <div className="u-fill-1 u-pad-large u-pad-xlarge@m">
            <div className="l-wrap u-width-5 u-align-center">
                <h1 className="c-heading +h1 u-marg-bottom">Warehousing</h1>
                <p className="u-font-large@m">
                    Burke Shipping Services Ltd. currently operate over 200,000 sq ft of flat storage dedicated to agri-products, this allows us to provide a full turnaround package. The services involved in a turnaround package include discharge, storage and delivery to customer.
                </p>
            </div>
        </div>

        <div className="l-wrap">
            <div className="c-media-text +background-image-fill">
                <div className="c-media-text__contents +contents-flipped">
                    <div className="c-media-text__a">
                        <div className="u-pad-top-bottom-xlarge@m">
                            <p className="u-font-large@m u-marg-bottom u-marg-bottom-large@m">We have a computerised stock management system linked directly to onsite weighbridges which allows us to ensure your materials are stored securely at all times.</p>
                            <p className="u-font-large@m">We can also provide Port storage for cargoes such as Wind turbines and various Project Cargoes.</p>
                        </div>
                    </div>

                    <div className="c-media-text__b">
                        <div className="c-media-text__media">
                            <img src="/images/warehousing-2.jpg" alt="Stevedore loading a lorry"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="u-fill-2 u-color-1 u-pad-top-bottom-large u-pad-top-bottom-xlarge@m">
            <div className="l-wrap u-align-center u-pad-left-right u-pad-left-right-large@m">
                <p className="u-font-large@m">For further information regarding our warehousing and storage please email <a href="mailto:agency@burkeshipping.co.uk" className="u-color-1 u-font-bold">agency@burkeshipping.co.uk</a> or <a href="/contact" className="u-color-1 u-font-bold">Contact Us</a></p>
            </div>
        </div>
    </StandardLayout>
};

export default Warehousing;